<template>
  <certificate-preview
     :certificate="certificate"
     role="партнер"
  />
</template>

<script>
// @ is an alias to /src
import { CertificatePreview } from "@/components";
import { mapActions, mapState } from 'vuex';

export default {
  name: "DealerOffeceCertificatePreview",
  components: {
    CertificatePreview
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('certificates', [
      "fetchSelfRetailCertificate",
      "fetchManufacturerIdSelfRetailCertificateId",
      "fetchSelfRetailCertificateByKey"
    ])
    
  },
  computed: {
    ...mapState('certificates', {
      certificate: state => { return {
        certificateName: state.selfretailCertificate.spotName,
        legalAdress: state.selfretailCertificate.dealerName,
        address: state.selfretailCertificate.spotAddress,
        certificateImage: state.selfretailCertificate.certificateImage,
        isShowManufacturerInfoInCertificate: state.selfretailCertificate.isShowManufacturerInfoInCertificate,
        manufacturerName: state.selfretailCertificate.manufacturerName,
        isRevoked: state.selfretailCertificate.isRevoked,
      }}
    })
  },
  created() {
    if (this.$route.params.certificateId)
      this.fetchManufacturerIdSelfRetailCertificateId({id: this.$route.params.id, certificateId: this.$route.params.certificateId})    
    else if (this.$route.query.Key)
      this.fetchSelfRetailCertificateByKey(this.$route.query.Key)    
    else
      this.fetchSelfRetailCertificate(this.$route.params.id)    
  },

};
</script>

<style lang="scss" scoped></style>